import React, { useEffect, useState } from 'react';
import {
  BrowserRouter, Route, Switch,
} from 'react-router-dom';
import './scss/style.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import { checkAuthentication } from './store';
import PrivateRoute from './utils/PrivateRoute';
import Preloader from './components/Preloader';
import 'moment/locale/ru';

const preloader = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

// Containers
const Layout = React.lazy(() => import('./containers/Layout'));

const Login = React.lazy(() => import('./views/pages/login/Login'));
const Courator = React.lazy(() => import('./views/pages/courator/Courator'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));

const App = () => {
  const [isInit, setIsInit] = useState(false);

  const initApp = async () => {
    await checkAuthentication();
    setIsInit(true);
  };

  useEffect(() => {
    initApp();
  }, []);

  return isInit ? (
    <BrowserRouter>
      <React.Suspense fallback={preloader}>
        <Switch>
          <Route exact path="/courator/:id" name="Куратор" render={(props) => <Courator {...props} />} />
          <Route exact path="/404" name="Страница не найдена" render={(props) => <Page404 {...props} />} />
          <Route exact path="/login" name="Вход" render={(props) => <Login {...props} />} />
          <PrivateRoute path="/" name="Главная" render={(props) => <Layout {...props} />} />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  ) : <Preloader />;
};

export default App;
