import axios from 'axios';
import getFormData from './getFormData';

class ApiClient {
  constructor() {
    const axiosApp = axios.create({
      baseURL: '/admin',
      withCredentials: true,
    });

    axiosApp.interceptors.response.use(
      (response) => (response.data.data),
      (error) => Promise.reject(error.response.data.message),
    );

    this.axios = axiosApp;
  }

  async login(email, password) {
    const loginReq = await this.axios.post('/login', {
      email,
      password,
    });
    return loginReq;
  }

  async logout() {
    const data = await this.axios.post('/logout');
    return data;
  }

  async checkAuthentication() {
    const data = await this.axios.get('/isAuthenticated');
    return data;
  }

  async createNews(values) {
    const formData = getFormData(values);
    const data = await this.axios.post('/news', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  }

  async updateNews(values) {
    const formData = getFormData(values);
    const data = await this.axios.patch('/news', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  }

  async deleteNews(id) {
    const data = await this.axios.delete(`/news/${id}`);
    return data;
  }

  async createFAQ(values) {
    const data = await this.axios.post('/faq', values);
    return data;
  }

  async updateFAQ(values) {
    const data = await this.axios.patch('/faq', values);
    return data;
  }

  async deleteFAQ(id) {
    const data = await this.axios.delete(`/faq/${id}`);
    return data;
  }

  async createEvent(values) {
    const formData = getFormData(values);
    const data = await this.axios.post('/events', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  }

  async updateEvent(values) {
    const formData = getFormData(values);
    const data = await this.axios.patch('/events', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  }

  async deleteEvent(id) {
    const data = await this.axios.delete(`/events/${id}`);
    return data;
  }

  async createForumCategory(values) {
    const formData = getFormData(values);
    const data = await this.axios.post('/forum/category', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  }

  async updateForumCategory(values) {
    const formData = getFormData(values);
    const data = await this.axios.patch('/forum/category', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  }

  async deleteForumCategory(id) {
    const data = await this.axios.delete(`/forum/category/${id}`);
    return data;
  }

  async deleteForumTopic(id) {
    const data = await this.axios.delete(`/forum/topic/${id}`);
    return data;
  }

  async deleteForumMessage(id) {
    const data = await this.axios.delete(`/forum/message/${id}`);
    return data;
  }

  async createFile(values) {
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('file', values.file);
    const data = await this.axios.post('/files', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  }

  async uploadFile(url, formData) {
    const data = await this.axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  }

  async deleteFile(id) {
    const data = await this.axios.delete(`/files/${id}`);
    return data;
  }

  async deleteMpPrice(id) {
    const data = await this.axios.delete(`/market/settings/priceLevel/${id}`);
    return data;
  }

  async rejectRequest(id) {
    const data = await this.axios.patch(`/requests/${id}/reject`);
    return data;
  }

  async approveRequest(id, freeApprove = false) {
    const data = await this.axios.patch(`/requests/${id}/approve`, {
      freeApprove,
    });
    return data;
  }

  async createData(url, values = {}, publicRoute = false, json = false) {
    const postData = json ? values : getFormData(values);
    const data = await this.axios.post(url, postData, {
      headers: {
        'Content-Type': json ? 'application/json' : 'multipart/form-data',
      },
      baseURL: publicRoute ? '/' : '/admin',
    });
    return data;
  }

  async deleteData(url, id) {
    const data = await this.axios.delete(`${url}/${id}`);
    return data;
  }

  async updateData(url, values = {}) {
    const formData = getFormData(values);
    const data = await this.axios.patch(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  }

  async updateJson(url, values = {}) {
    const data = await this.axios.patch(url, values);
    return data;
  }

  async getData({ url, query, publicRoute = false }) {
    let requestUri = url;
    if (query) {
      requestUri += `?${query}`;
    }
    const data = await this.axios.get(requestUri, { baseURL: publicRoute ? '/' : '/admin' });
    return data;
  }
}

export default new ApiClient();
